import { useSession } from "next-auth/react";
import Image from "next/image";

import { useShoppingCart } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Drawer, Stack, Typography } from "@mui/material";
import { ShoppingCart as Cart } from "@phosphor-icons/react";

import { ShoppingCartHeader } from "../ShoppingCartHeader";
import { EmptyShoppingCart } from "./EmptyShoppingCart";
import { ShoppingCartItem } from "./ShoppingCartItem";
import { styles } from "./styles";

export const ShoppingCart: React.FC = () => {
  const { cartItem, removeItem, totalPriceInCart, numberOfItemsInCart, checkout, loading, cartDisclose } = useShoppingCart();

  const { status, data } = useSession();

  return (
    <Drawer open={cartDisclose.isOpen} onClose={cartDisclose.onClose} anchor="right" css={styles.drawer}>
      <Stack divider={<Divider sx={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} />} pb={16}>
        <ShoppingCartHeader />

        {cartItem ? (
          <>
            <Stack spacing={2} p={4}>
              <Typography fontWeight={500} fontSize={16} color={theme.palette.text.secondary}>
                1 Item em 1 Parceiro
              </Typography>

              <Card variant="outlined" css={styles.drawer__card}>
                <CardHeader
                  avatar={
                    <Image
                      src={cartItem?.partner?.partnerLogo || "/placeholder.png"}
                      alt="Logo do parceiro"
                      width={50}
                      height={50}
                      style={{ borderRadius: "8px", objectFit: "contain" }}
                    />
                  }
                  css={styles.drawer__cardheader}
                />

                {cartItem?.productId ? (
                  <ShoppingCartItem
                    id={cartItem?.product?.id}
                    title={cartItem?.product?.description}
                    kmPrice={cartItem?.product?.productCoinValue}
                    logo={cartItem?.product?.imageUrl}
                    quantity={1}
                    removerItem={removeItem}
                  />
                ) : null}

                <CardContent css={styles.drawer__card_cardcontent}>
                  <Stack spacing={1}>
                    <Typography color={theme.palette.secondary.main} fontWeight={700} fontSize={14}>
                      Subtotal do Parceiro:
                      <span style={{ color: theme.palette.warning.main }}> {isNaN(totalPriceInCart) ? 0 : totalPriceInCart} pontos KMV</span>
                    </Typography>

                    <Typography color={theme.palette.secondary.main} fontWeight={700} fontSize={14}>
                      Quantidade:
                      <span style={{ color: theme.palette.warning.main }}> {numberOfItemsInCart.toString().padStart(2, "0")}</span>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>

              <Stack spacing={4} p={2} css={styles.drawer__stack}>
                <Stack spacing={1}>
                  <Typography color={theme.palette.secondary.main} fontWeight={700} fontSize={14}>
                    Total de pontos KMV utilizados:{" "}
                    <span style={{ color: theme.palette.warning.main }}>{isNaN(totalPriceInCart) ? 0 : totalPriceInCart} pontos KMV</span>
                  </Typography>

                  {status === "authenticated" && data.user?.balance?.balance ? (
                    <Typography color={theme.palette.secondary.main} fontWeight={700} fontSize={14}>
                      Saldo restante:{" "}
                      <span style={{ color: theme.palette.warning.main }}>{data.user?.balance?.balance - (totalPriceInCart || 0)} pontos KMV</span>
                    </Typography>
                  ) : null}
                </Stack>

                <Button variant="contained" onClick={checkout} disabled={loading} css={styles.drawer__button}>
                  <Typography color="#211b04" fontSize={15} fontWeight={600}>
                    PROSSEGUIR COM A COMPRA
                  </Typography>

                  {loading && <CircularProgress size="small" sx={{ width: 18, height: 18, ml: 2 }} color="secondary" />}
                </Button>
              </Stack>
            </Stack>

            <Button onClick={cartDisclose.onClose} size="small" sx={{ mt: 4 }}>
              <Cart size={18} color={theme.palette.secondary.main} css={styles.drawer__icon_shoppingcart} />

              <Typography color={theme.palette.secondary.main} fontSize={15} fontWeight={600}>
                CONTINUAR COMPRANDO
              </Typography>
            </Button>
          </>
        ) : (
          <EmptyShoppingCart />
        )}
      </Stack>
    </Drawer>
  );
};
