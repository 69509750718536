/* eslint-disable @typescript-eslint/ban-ts-comment */
import Image from "next/image";
import React from "react";

import LogoKMV from "@/assets/img/KMV_Logo_RGB_AzulTagline.png";
import { ShoppingCart } from "@/components/ShoppingCart";
import { useShoppingCart } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { Badge, Box, IconButton, styled, Toolbar, Tooltip, useMediaQuery } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { List, ShoppingCart as Cart } from "@phosphor-icons/react";

import { NextMuiLink } from "../Link/NextMuiLink";
import Login from "../Login/Login";
import { drawerWidth } from "../Menu/styles";
import { HeaderItem } from "./HeaderItem";
import { HeaderUserInfo } from "./HeaderUserInfo";
import { styles } from "./styles";
import { AppBarProps, HeaderProps } from "./types";
import { HeaderSearch } from "./HeaderSearch/HeaderSearch";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Header: React.FC<HeaderProps> = ({ open, onToggle, menuItems }) => {
  const { numberOfItemsInCart, cartDisclose } = useShoppingCart();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar css={styles.header} open={open}>
        <Toolbar css={styles.header__toolbar}>
          <Box css={styles.header__box_mr}>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={onToggle}>
              <List size={28} color="#003863" />
            </IconButton>

            <NextMuiLink href="/">
              <Box display="flex" gap={2}>
                <Image src={LogoKMV} alt="Logo KMV" height={isSmallScreen ? 45 : 40} />
              </Box>
            </NextMuiLink>
          </Box>

          <HeaderItem menuItems={menuItems} />

          <Box css={styles.header__box}>
            <Box css={styles.header__list}>
              <Login showButton={true} />
              <HeaderUserInfo closeCart={cartDisclose.onClose} />
            </Box>

            <Tooltip title="Meu Carrinho">
              <Badge badgeContent={numberOfItemsInCart ?? 0} showZero={false} css={styles.header__badge}>
                <IconButton color="inherit" onClick={cartDisclose.onToggle} css={styles.header__button}>
                  <Cart size={24} color={theme.palette.common.white} />
                </IconButton>
              </Badge>
            </Tooltip>

            <ShoppingCart />
          </Box>
        </Toolbar>
        <HeaderSearch />
      </AppBar>
    </>
  );
};

export default Header;
