/* eslint-disable react/display-name */
import { signIn, useSession } from "next-auth/react";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";

import Modal from "@/components/Modal/Modal";
import { useToast } from "@/hooks/useToast";
import { useDisclose, useLoader } from "@/hooks/utilHooks";
import { formLoginSchema } from "@/schemas/formLoginSchema";
import theme from "@/styles/themes/theme";
import { INPUT_MASKS, MaskUtils } from "@/utils/masks";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Eye, EyeSlash, SignIn } from "@phosphor-icons/react";

import { CaptchaFormFooter } from "../CaptchaFormFooter";
import { ForgotPasswordModal } from "../ForgotPasswordModal";
import { SignUpModal } from "../SignUpModal";
import { styles } from "./styles";
import { LoginProps, LoginRef } from "./types";
import { MaskedInput } from "../Inputs";

const Login = forwardRef<LoginRef, LoginProps>((props, ref) => {
  const { onOpen, onClose, isOpen } = useDisclose();
  const forgotPasswordDisclose = useDisclose();
  const signUpModalDisclose = useDisclose();
  const showPassword = useDisclose();
  const isScreenSmall = useMediaQuery(() => theme.breakpoints.down("md"));
  const { status } = useSession();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { hideLoader, loading, showLoader } = useLoader();

  const { showToast } = useToast();

  const form = useForm({ defaultValues: { cpf: "", password: "" }, resolver: formLoginSchema });

  const handleSubmit = async ({ cpf, password }: { cpf: string; password: string }) => {
    try {
      if (!executeRecaptcha) {
        throw new Error("Recaptcha não está pronto.");
      }

      showLoader();

      const recaptchaToken = await executeRecaptcha("login");

      const unmasked = MaskUtils.unmaskNumber(cpf);

      const login = unmasked?.length < 11 ? `0${unmasked}` : unmasked;

      const response = await signIn("credentials", {
        redirect: false,
        login,
        password,
        recaptchaToken
      });

      if (response?.error) {
        throw new Error(response?.error);
      } else if (response?.ok) {
        onClose();
      }
    } catch (error) {
      showToast({
        title: `${error as Error}`,
        severity: "error"
      });
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      form.reset({ cpf: "", password: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useImperativeHandle(ref, () => ({
    onOpen
  }));

  if (status !== "unauthenticated") {
    return null;
  }

  return (
    <>
      {props?.showButton ? (
        <Tooltip title={isScreenSmall ? "Entre" : null}>
          <ListItemButton css={styles.header__listitembutton} onClick={onOpen}>
            <SignIn size={24} color={theme.palette.common.black} />

            {!isScreenSmall ? (
              <ListItemText
                secondary="Entre"
                secondaryTypographyProps={{
                  style: {
                    fontWeight: 600
                  }
                }}
              />
            ) : null}
          </ListItemButton>
        </Tooltip>
      ) : null}

      <Modal open={isOpen} onClose={onClose} maxWidth="xs" title="Entre com sua conta KMV" buttons="Fechar">
        <Stack mt={2} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Box boxShadow={3} bgcolor="white" borderRadius="16px" padding={2}>
            <Typography variant="h6" mb={2}>
              Login
            </Typography>

            <FormProvider {...form}>
              <MaskedInput name="cpf" id="cpf" label="CPF" placeholder="___.___.___-__" mask="###.###.###-##" />
              <Controller
                name="password"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth sx={{ marginTop: 2 }} error={Boolean(fieldState.invalid)}>
                    <InputLabel htmlFor="password">Senha</InputLabel>

                    <OutlinedInput
                      label="Senha"
                      id="password"
                      disabled={loading}
                      type={showPassword.isOpen ? "text" : "password"}
                      inputComponent={IMaskInput}
                      inputProps={{ mask: INPUT_MASKS.SIX_DIGITS }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={showPassword.onToggle}>
                            {showPassword.isOpen ? <Eye size={20} color="#949299" /> : <EyeSlash size={20} color="#949299" />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </FormProvider>

            <Button
              sx={{ marginTop: 2 }}
              variant="text"
              color="secondary"
              onClick={() => {
                onClose();
                forgotPasswordDisclose.onOpen();
                // forgotPasswordDisclose.onOpen();
              }}
            >
              ESQUECI MINHA SENHA
            </Button>

            <Stack direction="column" mt={2}>
              <CaptchaFormFooter />

              <Button
                sx={{ marginTop: 2, color: "black" }}
                fullWidth
                variant="contained"
                onClick={form.handleSubmit(handleSubmit)}
                disabled={loading}
              >
                ENTRAR {loading ? <CircularProgress size="small" sx={{ width: 24, height: 24, ml: 2 }} color="secondary" /> : null}
              </Button>
            </Stack>
          </Box>

          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px"
            }}
          >
            <Typography variant="body1">Não tem uma conta?</Typography>

            <Button
              sx={{ marginTop: 1, borderRadius: "4px" }}
              color="secondary"
              variant="contained"
              onClick={() => {
                onClose();
                signUpModalDisclose.onOpen();
              }}
            >
              CRIE SUA CONTA
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <SignUpModal isOpen={signUpModalDisclose.isOpen} onClose={signUpModalDisclose.onClose} />

      <ForgotPasswordModal isOpen={forgotPasswordDisclose.isOpen} onClose={forgotPasswordDisclose.onClose} />
    </>
  );
});

export default Login;
