import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

const heightMenu = "64px";

export const styles = {
  drawer: css`
    /* width: 360px; */
    margin-top: ${heightMenu};

    .MuiBackdrop-root {
      margin-top: ${heightMenu};

      @media (max-width: 599px) {
        margin-top: 56px;
      }
    }
    .MuiPaper-root {
      margin-top: ${heightMenu};

      @media (max-width: 599px) {
        margin-top: 56px;
      }

      @media (max-width: 384px) {
        min-width: 300px;
      }
    }
  `,
  drawer__badge: css`
    .MuiBadge-badge {
      right: 6px;
      top: 6px;
    }
  `,
  drawer__icon: css`
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    pointer-events: none;
    background-color: ${theme.palette.warning.dark};

    &:hover {
      background-color: ${theme.palette.warning.dark};
    }
  `,
  drawer__card: css`
    border: 1px solid ${theme.palette.grey[300]};

    &.MuiPaper-root {
      margin-top: 10px;
      padding-top: 16px;
    }
  `,
  drawer__cardheader: css`
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  `,
  drawer__divider: css`
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  `,
  drawer__icon_trash: css`
    margin-left: 16px;
    margin-right: 8px;
  `,
  drawer__card_cardcontent: css`
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: ${theme.palette.grey[200]};

    &.MuiCardContent-root {
      padding-bottom: 16px;
    }
  `,
  drawer__button: css`
    padding-left: 8px;
    padding-right: 8px;
  `,
  drawer__icon_shoppingcart: css`
    margin-right: 8px;

    @media (max-width: 384px) {
      width: 32px;
      height: 32px;
    }
  `,
  drawer__stack: css`
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: ${theme.palette.grey[200]};
  `
};
