import theme from "@/styles/themes/theme";
import { css } from "@emotion/react";

export const drawerWidth = 260;

export const styles = {
  modal__stack_block: css`
    width: 51%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    background-color: ${theme.palette.common.white};

    @media (max-width: 900px) {
      width: 100%;
    }
  `,
  modal__toggleButton: css`
    .MuiToggleButtonGroup-grouped {
      border: 1px solid var(--light-other-divider, rgba(0, 0, 0, 0.12));
      border-radius: 4px;
    }
  `,
  modal__typography_km: css`
    color: ${theme.palette.info.main};
    font-weight: 900;

    @media (max-width: 680px) {
      font-size: 36px;
    }
  `
};
