import { useShoppingCart } from "@/context/ShoppingCartContext";
import theme from "@/styles/themes/theme";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { X } from "@phosphor-icons/react";

export const ShoppingCartHeader: React.FC = () => {
  const { cartDisclose } = useShoppingCart();

  return (
    <Stack px={4} py={2} direction="row" spacing={1} justifyContent="space-between" alignItems="center" width="100%">
      <Typography fontWeight={600} fontSize={16} color={theme.palette.text.secondary} mb={2}>
        Meu Carrinho
      </Typography>

      <Tooltip title="Fechar">
        <IconButton aria-label="close" color="inherit" onClick={cartDisclose.onClose}>
          <X fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
