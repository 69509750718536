const CONTENTFUL_ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT || "";
const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID || "";
const CONTENTFUL_ACCESS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN || "";
const CONTENTFUL_PREVIEW_ACCESS_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN || "";
const CLIENT_SECRET = process.env.CLIENT_SECRET || "";
const CLIENT_ID = process.env.CLIENT_ID || "";
const FOOTBALL_API_URL = "https://webapi.abasteceai-futebol.com.br/matches/showcase";
const PARTNERS_SERVICES_API_URL = "http://ipiranga.kmvservicos.com.br/apiKMV/Parceiros/api/ServicoParceiros";
const MULE_API_URL = "https://authkmv.kmdevantagens.com.br/mule/kmv/api/v1";
const GATEWAY_API_URL = process.env.GATEWAY_API_URL || "";
const ALLOWED_DOMAINS = process.env.ALLOWED_DOMAINS || "";
const ALLOWED_SCHEMES = process.env.ALLOWED_SCHEMES || "";
const {
  MARKETING_MAIL_AUTH_URL,
  MARKETING_MAIL_CLIENT_ID,
  MARKETING_MAIL_CLIENT_SECRET,
  MARKETING_MAIL_ACCOUNT_ID,
  MARKETING_MAIL_REST_SUBSCRIPTION_URL
} = process.env;

enum contentfulContentTypesEnum {
  /* Página Inicial */
  homePage = "homePage",
  /* Carousel de Parceiros */
  partnersCarousel = "partnersCarousel",
  /* Carousel de Logos */
  logosCarousel = "logosCarousel",
  /* Categoria de Parceiros */
  partnersCategory = "partnersCategory",
  /* Parceiro */
  partner = "partner",
  /*Pagina de Parceiros e informativos */
  partnersInformationPage = "partnersInformationPage",
  /* Página de Ajuda */
  helpPage = "helpPage",
  /* Página Abastece Aí */
  abasteceAiPage = "abasteceAiPage",
  /* Página Caminhoneiros */
  truckersPage = "truckersPage",
  /* Página Como Funciona */
  howItWorksPage = "howItWorksPage",
  /* Página Promoçao */
  promotionItem = "promotionItem",
  /* Configuração da plataforma */
  platformConfiguration = "platformConfiguration",
  /* Página Institucional */
  institutionalPage = "institutionalPage",
  /* Configuração da Página Movida */
  movidaPage = "movidaPage",
  /* Configuração da Página do Ganhe KM */
  earnKmPage = "earnKmPage",
  /* Configuração da Página de Ouvidoria */
  ombudsmanPage = "ombudsmanPage",
  /* Configuração da Página de Segurança */
  securityPage = "securityPage",
  /* Configuração da Página Blog */
  blogPage = "blogPage",
  /* Categproa de artigo da Página Blog */
  articleCategory = "articleCategory",
  /* Artigo da Página Blog */
  blogArticle = "blogArticle",
  /* Configuração da Página de Segurança */
  financialEducationPage = "financialEducationPage",
  // Configuração da Página 404
  errorPageNotFound = "errorPageNotFound",
  /* Privacidade e Proteção de Dados */
  PrivacyAndDataProtectionPage = "privacyAndDataProtectionPage",
  /* Relatórios */
  reportsPage = "reportsPage",
  /* Relatórios de Transparência e Igualdade Salarial de Mulheres e Homens */
  salaryReportPage = "salaryReportPage",
  /* Menu */
  menuItem = "menuItem"
}

/**
 * Constants to be used on frontend
 */
export const Constants = Object.freeze({
  footballApiUrl: FOOTBALL_API_URL,
  contentfulContentTypesEnum,
  muleApiUrl: MULE_API_URL,
  gatewayApiUrl: GATEWAY_API_URL
} as const);

/**
 * Constants to be used on backend
 */
export const config = Object.freeze({
  contentfulEnvironment: CONTENTFUL_ENVIRONMENT,
  contentfulSpaceId: CONTENTFUL_SPACE_ID,
  contentfulAccessToken: CONTENTFUL_ACCESS_TOKEN,
  contentfulPreviewAccessToken: CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  partnersServicesApiUrl: PARTNERS_SERVICES_API_URL,
  clientSecret: CLIENT_SECRET,
  clientId: CLIENT_ID,
  gatewayApiUrl: GATEWAY_API_URL,
  allowedDomains: ALLOWED_DOMAINS,
  allowedSChemes: ALLOWED_SCHEMES,
  blogEmailSubscription: {
    authUrl: MARKETING_MAIL_AUTH_URL,
    clientId: MARKETING_MAIL_CLIENT_ID,
    clientSecret: MARKETING_MAIL_CLIENT_SECRET,
    accountId: MARKETING_MAIL_ACCOUNT_ID,
    restUrl: MARKETING_MAIL_REST_SUBSCRIPTION_URL
  }
} as const);
